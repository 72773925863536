body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@font-face {
  font-family: 'Averta-Black';
  src: local('Averta-Black'), url(./Averta-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Averta-Bold';
  src: local('Averta-Bold'), url(./Averta-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Averta-Regular';
  src: local('Averta-Regular'), url(./Averta-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Averta-Semibold';
  src: local('Averta-Semibold'), url(./Averta-Semibold.ttf) format('truetype');
}

@font-face {
  font-family: 'colleges';
  src: local('colleges'), url(./colleges.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Black';
  src: local('Roboto-Black'), url(./Roboto-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url(./Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url(./Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Light';
  src: local('Roboto-Light'), url(./Roboto-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Thin';
  src: local('Roboto-Thin'), url(./Roboto-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url(./Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'ClanOT-Ultra';
  src: local('ClanOT-Ultra'), url(./ClanOT-Ultra.ttf) format('truetype');
}
@font-face {
  font-family: 'ClanOT-Black';
  src: local('ClanOT-Black'), url(./ClanOT-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'ClanOT-Bold';
  src: local('ClanOT-Bold'), url(./ClanOT-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'ClanOT-Medium';
  src: local('ClanOT-Medium'), url(./ClanOT-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'ClanOT-News';
  src: local('ClanOT-News'), url(./ClanOT-News.ttf) format('truetype');
}
