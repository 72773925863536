.background-main {
  background-image: linear-gradient(#0047FF, #42E1FF);
  height: 100vh;
  width: 100vw;
}

.background-grey {
  background-color: #F2F4F7;
  height: 97vh;
  width: 100vw;
}

.background-color {
  background: linear-gradient(0.1turn,#1481FF,#871BFF,#EE139F,#FD5152);
  height: 98vh;
  width: 100vw;
}

.background-game {
  background-image: linear-gradient(0.5turn, #0047FF, #42E1FF);
  height: 98vh;
  width: 100vw;
}

.gradient-main {
  background: linear-gradient(0.67turn,#FD5152,#EE139F,#871BFF,#1481FF);
}

.gradient-text {
  font-size: 32px;
  /* background: -webkit-linear-gradient(0.67turn,#1481FF,#871BFF,#EE139F,#FD5152); */
  /* background: linear-gradient(0.12turn, #0047FF, #42E1FF); */
  background: #0047FF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-button {
  background: -webkit-linear-gradient(0.67turn,#1481FF,#871BFF,#EE139F,#FD5152);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.background-main2 {
  background-image: linear-gradient(#25BCFE, #AAE5FE, white);
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-content: center;
  text-align: center;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: white;
  align-self: center;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100vh;
}

.verticalAlignMiddle {

}

.background-test {
  background-color: yellow;
}

.button-pollgenerate {
  background: yellow;
}

.button-pollgenerate-label {
  /* background-color: yellow; */
  font-family: 'Averta-Bold';
}

.flexRow {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


/* POLLS */
.poll-wrapper {
  padding: 20px;
  max-width: 500px;
  margin: auto;
}

.poll-wrapper-inside {
  border-radius: 28px;
  padding: 15px;
  background: white;
  z-index: 100;
  position: relative;
  margin-top: -10px;
}

.poll-title {
  font-family: 'Averta-Black';
  font-size: 18px;
  color: #2F3350;
  margin-top: 5px;
  margin-bottom: 20px;
}

.poll-option-left {
  width: 50%;
  padding-right: 7.5px;
}

.poll-option-right {
  width: 50%;
  padding-left: 7.5px;
}

.poll-option-wrapper-inside {
  border-radius: 20px;
  height: 55px;
  background: #25BCFE;
  align-content: center;
  border-width: 0px;
}

.poll-option-wrapper-inside:focus {
  outline: none !important;
  outline-offset: none !important;
  background-color: #FEFF60;
}

.poll-option-wrapper-inside p {
  font-family: 'Averta-Bold';
  font-size: 16px;
  color: white;
  margin-top: 14px;
  padding: 5px;
  line-height: 16px;
}

.poll-option-wrapper-inside:focus p {
  color: black;
}

.poll-pick-winner {
  font-family: 'Averta-Bold';
  font-size: 18px;
  text-transform: lowercase;
  color: #130735;
  opacity:0.5;
  margin-top: 0px;
  margin-bottom: 20px;
}

.poll-bitmoji {
  height:120px;
  width:120px;
  position: relative;
}

.cloud-background {
  width:100vw;
  height:auto;
  position: absolute;
  bottom:0px;
  left:0px;
  opacity: 0.67;
}

.vertical-spacing-bottom {
  margin-bottom: 15px;
}


.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexCenter {
  justify-content: center;
  align-items: center;
}

.flexBetween {
  justify-content: space-around;
  align-items: center;
}

.flexStart {
  justify-content: flex-start;
  align-items: center;
  height:100vh;
  padding-top: 100px;
}

.sticker-wrapper {
  padding: 20px;
  max-width: 500px;
  margin: auto;
  margin-top: 100px;
}

.sticker-wrapper-inside {
  border-radius: 28px;
  padding: 15px;
  background: white;
  z-index: 0;
}

.sticker-image {
  height:50px;
  width:50px;
  position: relative;
  z-index:20;
  margin-bottom: -27px;
}

.sticker-image-appicon {
  height:40px;
  width:40px;
  position: relative;
  z-index:20;
  margin-bottom: -18px;
  border-radius: 12px;
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.12);
  -moz-box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.12);
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.12);
}

.sticker-title {
  font-family: 'Averta-Black';
  font-size: 20px;
  text-transform: uppercase;
  color: #00BDFF;
  margin-top: 10px;
  margin-bottom: 5px;
  line-height: 22px;
}

.sticker-subtitle {
  font-family: 'Averta-Black';
  font-size: 18px;
  text-transform: uppercase;
  color: #2F3350;
  line-height: 18px;
  margin-top: 6px;
  margin-bottom: 10px;
}

.button-wrapper-outside {
  padding: 16px;
  max-width: 500px;
  margin: auto;
  margin-top: 0px;
  text-decoration: none;
}

.button-wrapper-outside a {
  text-decoration: none;
}

.button-wrapper {
  width:100%;
  height:48px;
  padding-left:24px;
  padding-right:24px;
  background-color: #4A00E0;
  border-radius: 24px;
  border-width: 0px;
  text-decoration: none;
}

.button-text {
  font-family: 'Averta-Bold';
  font-size: 18px;
  text-transform: lowercase;
  color: white;
  margin-top: 16px;
  text-decoration: none;
  letter-spacing: -0.5px;
}

.loading-font {
  font-family: 'Averta-Black';
  font-size: 24px;
  text-transform: lowercase;
  color: white;
}

.winner-font {
  font-family: 'Averta-Black';
  font-size: 20px;
  text-transform: uppercase;
  color: white;
  margin-bottom: 80px;
}

.button-wrapper:focus {
  outline: none !important;
  outline-offset: none !important;
}

.invite-subtitle {
  font-family: 'Averta-Black';
  font-size: 14px;
  text-transform: uppercase;
  color: #2F3350;
  line-height: 18px;
  margin-top: 5px;
  margin-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
}


.screenshot {
  margin-top: -8vh;
  height: 70vh;
}

.preload-image {
  height:1px;
  width:1px;
}






.landing-grid {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.height {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.vertical {
  align-self: center;
  text-align: center;
  margin-left: 0 !important;
  flex-direction: column;
}

.legalText {
  align-self: center;
  text-align: left;
  margin-left: 0 !important;
}

.titleText {
  color: white;
  font-size: 48;
  height: 15vh;
}

.footer {
  background-color: red;
}

.projects-grid {
  display: flex;
}

.mdl-navigation__link {
  padding: 20px !important;
  margin-bottom: 200px !important;
  color: black !important;
  font-weight: 500 !important;
  opacity: 1 !important;
}

.links-flex {
  flex-direction: row;
  justify-content: space-between;
  display: block !important;
  padding-top: 8px;
  padding-bottom: 8px;
}

.links-grid {
  flex-direction: row;
  text-align: center;
}

.links-gif {
  height:70px;
  width:70px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0px;
  align-self: center;
  margin: auto;
  transition: all .15s cubic-bezier(.11,.65,1,1.69);
}

.links-gif-view {
  width:100%;
  align-items: center;
  text-align: center;
}

.links-gif:hover {
  transform: scale(1.15);
}


/* Resume Page css */
.resume-right-col {
  background: #27221F;
  color: white;
  padding: 2em;
}

/* contact page css */

.contact-body {
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 2em;
}

.contact-grid {
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 550px;
  background: white;
  color: black;
  margin-bottom: 20px;
}

.contact-grid h2 {
  font-family: 'Anton', sans-serif;
}

.contact-grid p {
  font-family: 'Oxygen', sans-serif;
  font-weight: bold;
}

.contact-grid hr {
  border-top: 5px dotted black;
  width: 50%;
  margin: auto;
}

.contact-list i {
  font-size: 66px;
  padding-right: 5rem;
}

.header-wrapper {
  justify-content: center;
  align-items: center;
  text-align: center;
  align-content: center;
  width: 100%;
}

.header-text h3 {
  font-family: 'MuseoSans';
  color: white;
  max-width: 400px;
  margin: auto;
  padding-bottom: 20px;
}

.header-text h6 {
  font-family: 'MuseoSans';
  color: white;
}

.header-text {
  padding-bottom: 20px;
}




/* contact page css */

.avatar-img {
  max-width: 275px;
  vertical-align: middle;
  align-self: center;
  margin-top: -50px;
  margin: auto;
  transition: all .15s cubic-bezier(.11,.65,1,1.69);
}

.avatar-img:hover {
  transform: scale(1.05);
}

.slider-image {
  width: 275px;
  height:568px;
}



.banner-text {
  background-color: black;
  opacity: .8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}

.banner-text h1 {
  font-size: 66px;
  font-weight: bold;
  color: white;
}

.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}

.banner-text p {
  color: white;
  font-size: 20px;
  padding: 1em;
}

.social-links {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.social-links i {
  color: white;
  font-size: 5em;
  padding: 8px;
}

/*Map */
.map-text1 {
  font-family: 'Averta-Black';
  font-size: 14px;
  color: #2F3350;
}

.map-text2 {
  font-family: 'Averta-Bold';
  font-size: 12px;
  color: #2F3350;
}

.map-text3 {
  font-family: 'Averta-Regular';
  font-size: 12px;
  color: #2F3350;
}

.map-separator {
  margin-bottom: 5px;
}

.map-currentDate {
  font-family: 'Averta-Bold';
  font-size: 14px;
  text-transform: lowercase;
  color: #2F3350;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  margin-top: 2px;
}

.map-wrapper {
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 50px;
  border-radius: 20px;
  background-color: 'white'
}
